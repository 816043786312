import UserPropertyFilterComponent from '@/components/chartFilters/userPropertyFilter/userPropertyFilter';
import segmentModule from '@/services/sessionFilter';

export default {
  name: 'createSegment',
  components: {
    UserPropertyFilterComponent
  },
  data() {
    return {
      title: 'Create Segment',
      segmentName: '',
      creatingSegment: false,
      fetchingSegmentDetails: false,
      segmentFilters: null
    };
  },
  methods: {
    async createSegment() {
      // Validate Segment Name.
      if (!this.segmentName) {
        this.errorToast('Segment name is required.');
        return;
      } else if (this.segmentName.length > 100) {
        this.errorToast('Segment name can not exceed 100 characters.');
        return;
      }

      this.creatingSegment = true;

      // Call api
      try {
        let params = {
          filterName: this.segmentName,
          filterData: this.$refs.userFilter.getFilters()
        };
        let result = null;
        if (this.segmentId) {
          result = await segmentModule.updateSegment(this.segmentId, params);
          this.successToast('Segment Updated.');
        } else {
          result = await segmentModule.createSegment(params, this);
          this.segmentId = result.data.id;
          this.successToast('Segment Created.');
        }

        if (result.data.success) {
          this.$router.push('/segment/live');
        }

        this.creatingSegment = false;
      } catch (err) {
        console.log(err);
        this.errorToast('Something went wrong.');
        this.creatingSegment = false;
      }
    },

    backBtn() {
      this.$router.push('/segment/live');
    }
  },

  async created() {
    this.segmentId = this.$route.params.id;
    if (this.segmentId) {
      // Fetch segment details of segment
      this.fetchingSegmentDetails = true;
      let result = await segmentModule.getSegmentDetail(this.segmentId, this);

      if (!result.data) {
        // Show 404
        this.$router.replace('/404');
        return;
      }

      let segmentDetails = result.data;
      this.segmentName = segmentDetails.name;
      this.segmentFilters = JSON.parse(segmentDetails.data);
      this.fetchingSegmentDetails = false;
      this.title = 'Update Segment';
    } else if (this.$route.query.request) {
      // Check in session storage if request is there
      let requestId = this.$route.query.request;
      let localStorageInfo = window.sessionStorage.getItem(requestId);
      if (localStorageInfo) {
        this.segmentFilters = JSON.parse(localStorageInfo);
      }
    }
  }
};
