<template>
  <div id="createSegmentPage">
    <!-- Page Loader -->
    <div class="loadingDiv" v-if="fetchingSegmentDetails" v-loading="true" style="min-height: 300px; width: 100%"></div>

    <template v-else>
      <el-form ref="segmentForm">
        <!-- Header -->
        <div class="overflow-hidden border border-gray-100 sm:rounded-lg mb-4">
          <div class="bg-gray-50 border-b border-gray-200 px-3 py-2 leading-7 text-md">
            <a @click="backBtn()" style="cursor: pointer">
              <i class="el-icon-back"></i>
            </a>
            &nbsp; {{ title }}

            <a target="_blank" href="http://help.growlytics.in/en/articles/6404282-creating-segments-in-growlytics" class="float-right">
              <el-button @click="window.Intercom('showArticle', 6400898)" type="primary" plain size="mini">Learn more about segments <i class="el-icon-top-right"></i></el-button>
            </a>
          </div>

          <!-- Header With Filters -->
          <div class="bg-white px-3 py-2 border-b border-gray-200">
            <el-form-item prop="name" label="Segment Name">
              <el-input v-model="segmentName"></el-input>
            </el-form-item>
          </div>

          <div class="bg-white px-3 pt-3 pb-4 border-b border-gray-200">
            <UserPropertyFilterComponent :filterTitle="'Segment Criteria'" ref="userFilter" v-bind:formattedPropertyFilter="segmentFilters" v-bind:readOnly="false"></UserPropertyFilterComponent>
          </div>

          <div class="bg-white px-3 py-3">
            <el-button type="primary" :loading="creatingSegment" :readOnly="false" @click="createSegment">{{ title }}</el-button>
          </div>
        </div>
      </el-form>
    </template>
  </div>
</template>

<style lang="scss" src="./createSegment.scss"></style>

<script>
import createSegmentComponent from './createSegmentComponent';
export default createSegmentComponent;
</script>
